import React from "react";
import { graphql } from "gatsby";

import styled from "styled-components";
import { StyledIconBase } from "@styled-icons/styled-icon";

import SearchEngineOptimization from "../components/utility/SearchEngineOptimization";

import MainNewsletter from "../components/forms/MainNewsletter";
import ArticlesSection from "../components/blog/ArticlesSection";
import SocialMediaSection from "../components/general/SocialMediaSection";
import Events from "../components/general/Events";
import FeaturedProducts from "../components/general/FeaturedProducts";
import SupportBand from "../components/general/SupportBand";

const IconStyleWrapper = styled.div`
  margin-top: 1% !important;

  @media (max-width: 1200px) {
    margin-top: 0% !important;
  }

  ${StyledIconBase} {
    width: 48px;
    height: 48px;
    top: 0.125em;
    position: relative;
    vertical-align: initial;
    margin-right: 1rem;
  }
`;

export function Head({ location, ...props }) {
  return (
    <SearchEngineOptimization
      {...props}
      meta={{ title: "Home" }}
      location={location}
    />
  );
}

export default function IndexPage({
  data: {
    blogs: { nodes: blogs },
    signupVideo,
    channels: { nodes: channels },
    about,
    events: { nodes: events },
    bigcommerce: { edges: bigcommerce },
    paymentGateways,
  },

  location,
}) {
  return (
    <>
      <IconStyleWrapper>
        <MainNewsletter video={signupVideo} />
        <SocialMediaSection channels={channels} />
        <Events events={events} />
        <ArticlesSection blogs={blogs} header="BLOG" />
        <FeaturedProducts bigcommerce={bigcommerce} />
        <SupportBand support={paymentGateways} />
      </IconStyleWrapper>
    </>
  );
}

export const query = graphql`
  query {
    blogs: allSanityBlog(
      filter: { publicityStatus: { eq: "PUBLISHED" }, featured: { eq: true } }

      limit: 4
      sort: { fields: [_updatedAt], order: DESC }
    ) {
      nodes {
        _updatedAt
        title
        description
        slug {
          current
        }
        blogCategory {
          title
          slug {
            current
          }
        }
        tags {
          slug {
            current
          }
        }
        textEditor {
          _key
          _type
          _rawRichText
        }
        image {
          image {
            asset {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
        }
      }
    }

    signupVideo: sanityVideo(title: { eq: "Newsletter Signup" }) {
      allow
      frameborder
      fullscreen
      height
      loop
      title
      url
      width
    }

    channels: allSanityChannel {
      nodes {
        title
        url
        image {
          image {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }

    events: allSanityEvent {
      nodes {
        address
        end
        start
        title
        url
        embed
      }
    }

    paymentGateways: sanitySection(title: { eq: "Support The Band" }) {
      textEditor {
        _rawRichText(resolveReferences: { maxDepth: 10 })
      }
      title
    }

    bigcommerce: allBigCommerce(filter: { is_visible: { eq: true } }) {
      edges {
        node {
          primary_image {
            url_standard
            description
          }
          custom_url {
            url
          }
          name
        }
      }
    }
  }
`;
