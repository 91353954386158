import React from "react";
import Linka from "../utility/Linka";
import styled from "styled-components";
import moment from "moment";

const EventStyles = styled.div`
  @media (max-width: 900px) {
    h2 {
      text-align: center;
    }
  }
  .events h2 {
    margin-bottom: 0;
  }

  .event-listing {
    overflow-y: scroll;
    max-height: 750px;
  }

  .event {
    display: grid;
    grid-template-columns: 120px auto 1fr;
    margin-bottom: 2rem;
    gap: 1rem;

    .date {
      background-color: var(--white);
      padding: 10px;
      color: var(--color3);
      text-align: center;
      margin-right: 1rem;

      .month {
        font-size: 1rem;
      }

      .day {
        font-size: 1.5rem;
      }

      .year {
        border-bottom: 1px solid var(--color3);
        padding-bottom: 5px;
      }

      .time {
        font-size: 1.2rem;
        padding-top: 5px;
      }
    }
    .details {
      padding: 10px;
      color: var(--color6);

      .title {
        font-size: 1.5rem;
        padding-bottom: 5px;
      }

      .timeframe > div {
        padding-bottom: 10px;
      }

      .location > div:first-child {
        padding-bottom: 5px;
      }
    }

    .map {
      height: auto;
    }
  }

  @media (max-width: 900px) {
    .event {
      grid-template-columns: 120px 1fr;
    }

    .date {
      margin-bottom: 1rem;
    }

    .map {
      display: block;
    }
  }

  @media (max-width: 500px) {
    .event {
      grid-template-columns: 1fr;
    }

    .date {
      display: block;
      margin-bottom: 1rem;
    }
  }
`;

const Events = ({ events }) => {
  return (
    <EventStyles className="bkg-color reversed">
      <div id="events" className="events">
        <h2>EVENTS</h2>

        <div className="event-listing">
          {events?.length ? (
            events.map((event, i) => (
              <div key={i} className="event">
                <div className="date">
                  <div className="month">
                    {moment(event.start).format("MMM").toUpperCase()}
                  </div>
                  <div className="day">{moment(event.start).format("DD")}</div>
                  <div className="year">
                    {moment(event.start).format("YYYY")}
                  </div>

                  <div className="time">
                    {moment(event.start).format("h:mm A")}
                  </div>
                </div>

                <div className="details">
                  <div className="title">{event.title}</div>
                  <div className="timeframe">
                    <div>
                      Date: {moment(event.start).format("dddd, MMMM DD YYYY")}
                    </div>
                    <div>
                      Time: {moment(event.start).format("h:mm A")} -{" "}
                      {moment(event.end).format("h:mm A")}
                    </div>
                  </div>

                  {event.address && (
                    <div className="location">
                      <div>Location:</div>
                      <Linka
                        to={event.url}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <div>{event.address}</div>
                      </Linka>
                    </div>
                  )}
                </div>

                {event.address && (
                  <div className="map">
                    <iframe
                      src={event.embed}
                      width="400"
                      height="200"
                      style={{ border: 0 }}
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                      allowfullscreen=""
                      title={event.title}
                    ></iframe>
                  </div>
                )}
              </div>
            ))
          ) : (
            <h3>We are updating our events. Check back soon!</h3>
          )}
        </div>
      </div>
    </EventStyles>
  );
};

export default Events;
