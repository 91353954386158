import React from "react";
import styled from "styled-components";
import Linka from "../utility/Linka";

const ProductStyles = styled.div`
  @media (max-width: 900px) {
    h2 {
      text-align: center;
    }
  }

  .shop {
    a {
      color: var(--color3);
    }

    img {
      width: 200px;
      height: 200px;
      margin-right: 0;
    }
  }

  @media (max-width: 600px) {
    padding-left: 0;
    padding-right: 0;
  }

  .products {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 2rem;
    justify-items: center;

    img {
      width: 350px;
      max-width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

export default function FeaturedProducts({ bigcommerce, products }) {
  return (
    <ProductStyles className="bkg-color">
      <div className="shop">
        <h2>
          <Linka
            to="https://shop.familyilove.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            MUSIC, MERCH & ART
          </Linka>
        </h2>

        <div className="products">
          {bigcommerce.map(({ node: product }) => (
            <Linka
              to={"https://shop.familyilove.com" + product.custom_url.url}
              target="_blank"
              rel="noopener noreferrer"
              key={product.name}
            >
              <img
                src={product.primary_image.url_standard}
                alt={product.primary_image.description}
              />
            </Linka>
          ))}
        </div>
      </div>
    </ProductStyles>
  );
}
